export const GW_URL = `https://gw-qa.prikly.io`;
export const baseUrl = `${GW_URL}/empleados/v1`;
export const adminBaseUrl = `${GW_URL}/onboarding/v1`;

export const getToken = () => {
  return localStorage.getItem("token_onboarding");
};

const updateAuth = () => {
  const Config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return Config;
};

export const config = updateAuth();
